import React, { useState } from 'react'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { IoIosArrowDown } from 'react-icons/io'

interface DropdownProps {
	options: {
		key: string
		label: string | React.ReactNode
		link?: string
		onClick?: () => void
	}[]
}

const Dropdown: React.FC<DropdownProps> = ({ options }) => {
	const [isOpen, setIsOpen] = useState(false) // for controlling dropdown visibility
	const path = usePathname()

	return (
		<div className='relative'>
			<button
				className='bg-gray-800 text-white px-4 py-2 rounded-md cursor-pointer flex items-center space-2'
				onClick={() => setIsOpen(open => !open)} // toggle dropdown
			>
				Admin{' '}
				{
					<IoIosArrowDown
						className={`duration-300 ease-in-out ml-2 ${isOpen ? 'rotate-180' : ''}`}
					/>
				}
			</button>
			<div
				className={`absolute top-10 left-0 bg-white shadow-lg z-10 p-4 rounded-md w-48 transition-all duration-300 
        ${isOpen ? 'opacity-100 visible' : 'opacity-0 invisible'}`}
			>
				{options.map(option =>
					option.link ? (
						<Link
							key={option.key}
							href={option.link}
							className={`block px-2 py-1 rounded hover:bg-gray-200 ${path === option.link ? 'text-blue-500 font-semibold' : 'text-black'}`}
							onClick={() => setIsOpen(false)} // close dropdown when clicked
						>
							{option.label}
						</Link>
					) : (
						<button
							key={option.key}
							className='w-full text-left block px-2 py-1 rounded hover:bg-gray-200 text-black'
							onClick={() => {
								option.onClick?.()
								setIsOpen(false) // close dropdown
							}}
						>
							{option.label}
						</button>
					)
				)}
			</div>
		</div>
	)
}

export default Dropdown
