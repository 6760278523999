import React from 'react'

export enum companyRoles {
	Actor = 'Actor',
	AssistantDirector = 'Assistant Director',
	CostumeDesigner = 'Costume Design',
	SetDesigner = 'Set Design',
	SetAndLights = 'Set and Lights',
	SetAndCostumes = 'Set and Costumes',
	Director = 'Director',
	CoDirector = 'Co-Director',
	ProductionManager = 'Production Manager',
	Playwright = 'Playwright',
	Composer = 'Composer',
	StageManager = 'Stage Manager',
	AssistantStageManager = 'Assistant Stage Manager',
	MultiMediaSoundDesigner = 'Multi-Media/Sound Design',
	MultiMedia = 'Multi-Media',
	SoundDesigner = 'Sound',
	LightingDesigner = 'Lights',
	Animator = 'Animator',
	CulturalConsultant = 'Cultural Consultant',
	DisabilityDramaturg = 'Disability Dramaturg',
	MovementConsultant = 'Movement Consultant',
	IntimacyDirector = 'Intimacy Director',
	IntimacyDirectorMovementConsultant = 'Intimacy Director/Movement Consultant',
}

export const roleOrder = [
	companyRoles.Actor,
	companyRoles.Director,
	companyRoles.CoDirector,
	companyRoles.AssistantDirector,
	companyRoles.Playwright,
	companyRoles.StageManager,
	companyRoles.AssistantStageManager,
	companyRoles.CulturalConsultant,
	companyRoles.DisabilityDramaturg,
	companyRoles.CostumeDesigner,
	companyRoles.SoundDesigner,
	companyRoles.MultiMediaSoundDesigner,
	companyRoles.MultiMedia,
	companyRoles.SetDesigner,
	companyRoles.SetAndLights,
	companyRoles.SetAndCostumes,
	companyRoles.LightingDesigner,
	companyRoles.ProductionManager,
	companyRoles.MovementConsultant,
	companyRoles.IntimacyDirector,
	companyRoles.IntimacyDirectorMovementConsultant,
	companyRoles.Composer,
	companyRoles.Animator,
]

export enum SHOWS {
	CRESCENDO = 'Crescendo!',
	THE_DRAWER_BOY = 'The Drawer Boy',
	ROBOT_GIRLS = 'Robot Girls',
	TINY_BEAUTIFUL_THINGS = 'Tiny Beautiful Things'
}

export interface ICastAndCrew {
	id?: number
	name: string
	role: string
	image_url: string
	bio: React.ReactNode
	shows: SHOWS[]
}

export const castAndCrew: ICastAndCrew[] = []
